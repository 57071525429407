{
  "name": "app",
  "private": true,
  "sideEffects": false,
  "scripts": {
    "build:backend": "node scripts/buildBackend.mjs",
    "build:frontend": "node scripts/buildFrontend.mjs",
    "build": "npm run build:backend& npm run build:frontend& wait",
    "build:clean": "rm -rf dist; npm run build",
    "build:watch": "nodemon -e ts,tsx,js,svg,png,jpg,json -i dist --exec 'npm run build'",
    "build:check": "npm run build; npm run build:includes:check",
    "build:includes:watch": "nodemon -i src ./scripts/esbuild-analyser.mjs dist/fe_metafile.json typeorm 0",
    "build:includes:check": "node ./scripts/esbuild-analyser.mjs dist/fe_metafile.json typeorm 0",
    "cloc": "cloc --exclude-dir=node_modules,dist,normaladdr --not-match-f='package-lock\\.json' .",
    "docker:up": "npm run db:init:basic& docker-compose up",
    "docker:wait": "./scripts/wait-for-dev-services.sh",
    "clean": "rm -rf dist",
    "start": "dotenvx run -- nodemon dist/backend.cjs",
    "dev": "npm run docker:wait; concurrently -n be,fe,srv \"node scripts/buildBackend.mjs --watch\" \"node scripts/buildFrontend.mjs --watch\" \"npm:start\"",
    "format:check": "prettier --check .",
    "format:fix": "prettier --write .",
    "lint": "eslint src/",
    "typecheck": "tsc",
    "typecheck:watch": "tsc --watch",
    "test": "LOG_LEVEL=info dotenvx run -- jest --detectOpenHandles",
    "test:debug": "LOG_LEVEL=debug dotenvx run -- jest",
    "test:watch": "LOG_LEVEL=info dotenvx run -- jest --watch",
    "test:unit": "LOG_LEVEL=info dotenvx run -- jest src/",
    "test:integration": "LOG_LEVEL=info dotenvx run -- jest tests",
    "test:coverage": "LOG_LEVEL=info dotenvx run -- jest --coverage",
    "test:ci": "LOG_LEVEL=info dotenvx run -- jest",
    "db:init:basic": "npm run docker:wait; npm run migrate:refresh && npm run seed",
    "db:init": "npm run docker:wait; npm run migrate:refresh && npm run seed && npm run seed:properties",
    "seed": "npm run ts-node -- src/scripts/seed",
    "seed:properties": "npm run test -- zenKaiApiFullSeed",
    "migrate": "npm run typeorm -- migration:run -d src/migrations.js",
    "migrate:drop": "npm run typeorm -- schema:drop -d src/migrations.js",
    "migrate:refresh": "npm run migrate:drop && npm run migrate",
    "migrate:make": "npm run typeorm -- migration:create migrations/NewMigration",
    "typeorm": "npm run ts-node -- node_modules/.bin/typeorm",
    "ts-node": "dotenvx run -- ts-node -r tsconfig-paths/register --project tsconfig-ts-node.json"
  },
  "dependencies": {
    "@aws-sdk/client-s3": "^3.514.0",
    "@aws-sdk/credential-providers": "^3.514.0",
    "@aws-sdk/lib-storage": "^3.572.0",
    "@aws-sdk/s3-request-presigner": "^3.596.0",
    "@dotenvx/dotenvx": "^1.14.1",
    "@emotion/cache": "^11.11.0",
    "@emotion/react": "^11.11.3",
    "@emotion/server": "^11.11.0",
    "@emotion/styled": "^11.11.0",
    "@faker-js/faker": "^8.4.1",
    "@fast-csv/format": "^5.0.0",
    "@fragaria/address-formatter": "^5.3.0",
    "@googlemaps/google-maps-services-js": "^3.4.0",
    "@mui/material": "^5.16.7",
    "@trpc/client": "^11.0.0-rc.403",
    "@trpc/server": "^11.0.0-rc.403",
    "awesome-phonenumber": "^7.2.0",
    "classnames": "^2.5.1",
    "colors": "^1.4.0",
    "compression": "^1.7.4",
    "concurrently": "^8.2.2",
    "cookie-parser": "^1.4.6",
    "exceljs": "^4.4.0",
    "exifreader": "^4.23.3",
    "express": "^4.18.2",
    "formik": "^2.4.6",
    "http-errors": "^2.0.0",
    "ioredis": "^5.4.1",
    "jose": "^5.2.1",
    "mapbox-gl": "^3.4.0",
    "memoizee": "^0.4.15",
    "nodemailer": "^6.9.13",
    "nodemon": "^3.0.3",
    "pg": "^8.11.3",
    "pg-cursor": "^2.11.0",
    "pipedrive": "^22.5.0",
    "react": "^18.2.0",
    "react-cookie": "^7.2.0",
    "react-dom": "^18.2.0",
    "react-icons": "^5.0.1",
    "react-map-gl": "^7.1.7",
    "reflect-metadata": "^0.2.1",
    "sql-formatter": "^15.4.0",
    "ts-node": "^10.9.2",
    "tsconfig-paths": "^4.2.0",
    "type-fest": "^4.26.1",
    "typeorm": "^0.3.20",
    "typeorm-naming-strategies": "^4.1.0",
    "ulid": "^2.3.0",
    "universal-cookie": "^7.2.0",
    "universal-cookie-express": "^7.2.0",
    "use-debounce": "^10.0.3",
    "vhost": "^3.0.2",
    "winston": "^3.11.0",
    "xxhashjs": "^0.2.2",
    "zod": "^3.22.4",
    "zod-formik-adapter": "^1.3.0",
    "zod-validation-error": "^3.0.0"
  },
  "devDependencies": {
    "@art-suite/chained-test": "^1.6.6",
    "@eslint/compat": "^1.2.1",
    "@jest/globals": "^29.7.0",
    "@sinonjs/fake-timers": "^11.2.2",
    "@svgr/core": "^8.1.0",
    "@svgr/plugin-jsx": "^8.1.0",
    "@types/compression": "^1.7.5",
    "@types/cookie-parser": "^1.4.7",
    "@types/eslint__js": "^8.42.3",
    "@types/express": "^4.17.21",
    "@types/http-errors": "^2.0.4",
    "@types/jest": "^29.5.13",
    "@types/mapbox-gl": "^3.4.0",
    "@types/memoizee": "^0.4.11",
    "@types/morgan": "^1.9.9",
    "@types/multer": "^1.4.11",
    "@types/node": "^20.14.2",
    "@types/nodemailer": "^6.4.15",
    "@types/pg": "^8.11.6",
    "@types/pg-cursor": "^2.7.2",
    "@types/react": "^18.2.20",
    "@types/react-dom": "^18.2.7",
    "@types/vhost": "3.0.4",
    "@types/xxhashjs": "^0.2.4",
    "esbuild": "^0.20.1",
    "esbuild-plugin-manifest": "^1.0.3",
    "esbuild-plugin-sass": "^1.0.1",
    "eslint": "^9.13.0",
    "eslint-plugin-jsx-a11y": "^6.10.1",
    "eslint-plugin-react": "^7.37.1",
    "eslint-plugin-react-hooks": "^5.0.0",
    "globals": "^15.9.0",
    "jest": "^29.7.0",
    "jest-module-name-mapper": "^0.1.5",
    "prettier": "^3.3.3",
    "ts-jest": "^29.2.5",
    "typescript": "^5.5.4",
    "typescript-eslint": "^8.10.0"
  },
  "overrides": {
    "@googlemaps/url-signature": "1.0.32"
  },
  "engines": {
    "node": ">=20.0.0"
  },
  "jest": {
    "preset": "ts-jest",
    "testEnvironment": "node",
    "transform": {
      "^.+\\.tsx?$": [
        "ts-jest",
        {
          "useESM": true
        }
      ]
    },
    "moduleNameMapper": {
      "\\.(css|less)$": "<rootDir>/jest/StubExportObject.js",
      "\\.svg(\\?url)?$": "<rootDir>/jest/StubExportComponent.tsx",
      "\\.(gif|png|jpeg|jpg)$": "<rootDir>/jest/StubExportString.js",
      "~\\/(.*[-/a-zA-Z0-9_]+?)": "<rootDir>/src/$1"
    },
    "collectCoverageFrom": [
      "src/**/*.(tsx|ts|js|jsx)",
      "!src/tests/**/*.(tsx|ts|js|jsx)",
      "!**/*.test.(tsx|ts|js|jsx)"
    ],
    "watchPathIgnorePatterns": [
      "<rootDir>/dist/"
    ]
  },
  "version": "0.0.1"
}
