import styled from "@emotion/styled";

import { colors } from "~/clients/zen/styles";
import { kaiAvatar, chadAvatar } from "~/clients/zen/assets";

const NoteDiv = styled.div`
  background-color: white;
  border-radius: 20px;
  font-size: 14px;
  line-height: 30px;
  padding: 25px 16px;

  strong {
    font-weight: 600;
  }

  &.kaiizen {
    background-color: #dedff9;
  }

  .detail-row {
    align-items: center;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    .avatar {
      background-color: ${colors["blue-focus"]};
      border-radius: 49px;
      margin-right: 20px;
      height: 40px;
      width: 40px;
    }
  }

  .content {
    padding-right: 30px;
  }
`;

type PhotoNoteProps = {
  fromKaiizen?: boolean;
  date?: Date;
  location?: string;
  text: string;
};

export const PhotoNote = ({ fromKaiizen, text }: PhotoNoteProps) => {
  const username = fromKaiizen ? "Kai" : "Chad Markworth";
  //{date.toLocaleDateString("en-US")}
  return (
    <NoteDiv>
      <div className="detail-row">
        {fromKaiizen ? (
          <img src={`${kaiAvatar}`} alt="" />
        ) : (
          <img src={`${chadAvatar}`} alt="" />
        )}

        <div>
          <strong>{username}</strong> •
        </div>
      </div>
      {fromKaiizen ? (
        <div className="content">{text}</div>
      ) : (
        <div className="content">{text}</div>
      )}
    </NoteDiv>
  );
};
