import { createTheme } from "@mui/material/styles";
import { colors } from "~/clients/sharedStyles";

export const materialTheme = createTheme({
  typography: {
    fontFamily: `"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;`,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: `${colors.white}`,
          borderRadius: "10px",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: "white",
          left: "-4px",
          padding: "0 4px",
          position: "absolute",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          borderRadius: "10px",
          "& .MuiInputBase-root": {
            borderRadius: "10px",
          },
          input: {
            padding: "20px 20px 16px 20px",
            borderRadius: "10px",
          },
          " label.Mui-disabled": {
            color: `${colors["blue-primary"]}`,
            fontWeight: 600,
          },
          " .MuiInputBase-input.Mui-disabled": {
            backgroundColor: `${colors["gray-light"]}`,
          },
          " .Mui-disabled fieldset": {
            border: "none",
          },
          // input text color, error state
          "& .MuiInputLabel-root.Mui-error": {
            color: colors.red,
          },
          // input border, error state
          "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
            {
              borderColor: `${colors.red} !important`,
            },
          //input inset label, error state
          "& .MuiFormLabel-root.Mui-error.Mui-focused": {
            color: `${colors.red} !important`,
          },
          //input inset label
          "& .MuiFormLabel-root.Mui-focused": {
            color: colors["blue-primary"],
          },
          // input active border
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: `solid 1px ${colors["blue-primary"]}`,
            },
          // input text color
          "& .MuiInputBase-input": {
            color: colors["blue-primary"],
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors["gray-medium"],
          padding: 0,
          "&:hover": {
            background: "none",
          },
          "&:hover span svg:last-child": {
            color: colors["gray-medium"],
            transform: "scale(1)",
          },
          "&.Mui-checked": {
            backgroundColor: colors["blue-focus"],
            color: colors["blue-focus"],
            padding: 0,
          },
          "svg:last-child": {
            color: "white",
          },
          "&.Mui-disabled": {
            color: colors["gray-divider"],
            backgroundColor: colors["gray-divider"],
          },
          "&.Mui-disabled svg:last-child": {
            transform: "scale(1)",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiRadioGroup-root .MuiFormControlLabel-root": {
            gap: "9px",
            padding: "9px",
          },
          " label.Mui-focused": {
            color: `${colors["blue-primary"]}`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          width: "100%",
          "&:hover": {
            backgroundColor: colors["purple-light"],
          },
          "&.Mui-focusVisible": {
            backgroundColor: colors["purple-light"],
          },
          "&.Mui-selected.Mui-focusVisible": {
            backgroundColor: colors["purple-light"],
            fontWeight: 600,
          },
          "&.Mui-selected.Mui-focusVisible:hover": {
            backgroundColor: colors["purple-light"],
          },
          "&.Mui-selected:hover, &.Mui-selected:hover": {
            backgroundColor: colors["purple-light"],
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "& > .Mui-selected": {
            backgroundColor: `${colors.white} !important`,
          },
          "& > .Mui-selected:hover": {
            backgroundColor: colors["purple-light"],
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
          borderRadius: "8px",
          color: colors.white,
          "&.MuiAlert-standardSuccess": {
            background:
              "var(--Green-Gradient, linear-gradient(180deg, #6EE277 0%, #37A935 100%))",
          },
          "&.MuiAlert-standardInfo": {
            background:
              "var(--Blue-Gradient, linear-gradient(180deg, #8F9FFF 0%, #6980FB 100%))",
          },
          "&.MuiAlert-standardError": {
            background:
              "var(--Red-Gradient, linear-gradient(180deg, #F4604C 0%, #C6493E 100%))",
          },
          "> .MuiAlert-icon": {
            alignSelf: "start",
            color: colors.white,
          },
          " .MuiAlert-action": {
            alignSelf: "start",
            paddingTop: 0,
          },
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: 1,
          margin: 0,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          textTransform: "capitalize",
        },
      },
    },
  },
});
