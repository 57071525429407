import { useState } from "react";
import classNames from "classnames";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { propPriceStr, yearsFromYear } from "~/lib/presenters";

import { breakpointS, colors } from "~/clients/zen/styles";
import { Chevron } from "~/clients/zen/assets";
import { PhotoPill } from "~/clients/zen/widgets";

const accordionRow = css`
  align-items: center;
  border-bottom: solid 1px #e9edf7;
  display: flex;
  gap: 20px;
  height: 60px;
  justify-content: center;
  padding: 10px 60px 10px 44px;

  background-color: white;
  border-bottom: solid 1px #e9edf7;
  transition: background-color 0.2s;

  @media (max-width: ${breakpointS}) {
    padding: 10px 20px 10px 24px;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &__cell {
    color: #10045a;
    display: flex;
    font-size: 14px;
    justify-content: right;

    &:first-of-type {
      justify-content: left;
    }

    &--head {
      color: ${colors["gray-medium"]}
      font-size: 12px;
      font-weight: 500;
      text-transform: capitalize;
    }

    &:nth-child(2) {
      margin-left: auto;
    }

    &.half-width {
      //width: 50%;
    }

    &.third-width {
      width: 33%;
    }
  }


  &:last-of-type {
    border-bottom: none;
  }

  &.active {
    background: #f4f7fd;
    border-bottom: 0;
    border-radius: 10px;
  }

  .cell {
    color: #10045a;
    display: flex;
    font-size: 14px;
    justify-content: right;
    text-transform: capitalize;

    @media (max-width: ${breakpointS}) {
      font-size: 13px;
    }
    
    &:first-of-type {
      justify-content: left;
    }

    &--head {
      color: ${colors["gray-medium"]}
      font-size: 12px;
      font-weight: 500;
      text-transform: capitalize;

      &.third-width {
        text-align: right;
      }
    }

    &:nth-child(2) {
      margin-left: auto;
      text-align: right;
    }

    &.half-width {
      //width: 50%;
    }

    &.third-width {
      width: 33%;
    }

    svg.indicator {
      display: inline-block;
      height: 20px;
      margin-right: 20px;
      width: 20px;
    }
  }
`;

const ItemDiv = styled.div`
  margin: 0 auto;
  padding-top: 20px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  .titleVal {
    color: ${colors["blue-focus"]};
    margin-left: auto;

    @media (max-width: ${breakpointS}) {
      display: none;
    }

    &.mobileVisible {
      display: inline !important;
    }
  }
`;

const accordionBody = css`
  color: ${colors["gray-medium"]}
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;

  &.hidden {
    max-height: 0;
    transition: max-height 300ms;
  }

  &.visible {
    max-height: 600px;
    transition: max-height 500ms ease-in;
  }
`;

const accordionItemHead = css`
  align-items: center;
  background-color: #f4f7fe;
  border-radius: 20px 20px 20px 0;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  gap: 10px;
  max-height: 60px;
  padding: 30px 60px 30px 40px;
  position: relative;
  transition:
    background-color 0.2s,
    box-shadow 0.3s;

  &:before {
    background-color: ${colors["blue-focus"]};
    border-radius: 16px;
    content: " ";
    height: 20px;
    left: 20px;
    opacity: 0;
    position: absolute;
    transition: opacity linear 100ms;
    top: 20px;
    width: 4px;
  }

  &:hover {
    background: #fff;
    box-shadow:
      3px 3px 5px 1px rgba(16, 4, 90, 0.1),
      -3px -3px 5px 1px rgba(16, 4, 90, 0.1);
  }

  // down chevron
  svg.indicator {
    margin-top: 4px;
    position: absolute;
    right: 20px;
    transform: rotate(270deg);
    transition: transform linear 300ms;
  }

  &.expanded {
    color: ${colors["blue-focus"]};

    // purple vertical bar of an expanded item
    &:before {
      opacity: 100;
    }

    // up chevron
    svg.indicator {
      transform: rotate(90deg);
    }
  }

  &.active-category {
    background: #fff;
    box-shadow:
      3px 3px 5px 1px rgba(16, 4, 90, 0.1),
      -3px -3px 5px 1px rgba(16, 4, 90, 0.1);
  }

  span {
    font-weight: 600;
    text-decoration: none;
    text-transform: capitalize;
  }
`;

export const formatRenoArray = (renoArray?: number[] | null) => {
  if (!renoArray) {
    return "--";
  }

  return `${propPriceStr(renoArray[0])} - ${propPriceStr(renoArray[1])}`;
};

export const formatDetailAge = (year: number) => `${yearsFromYear(year)} years`;

export type AccRowDetails = {
  name: string | null;
  value?: string | number | null | any[];
  key?: string;
  formatFunc?: (arg: any) => any;
  photoState?: {
    count: number;
    isActive?: boolean;
    onClick: () => void;
  } | null;
};

type PropertyAccordionProps = {
  expandedDefault?: boolean;
  photoState?: null | {
    count: number;
    isActive?: boolean;
    onClick: () => void;
  };
  rows: AccRowDetails[];
  title: string;
  titleValue?: string | number | null;
  showMobileTitleValue?: boolean;
};

export const PropertyAccordion = ({
  expandedDefault,
  photoState,
  rows,
  showMobileTitleValue,
  title,
  titleValue,
}: PropertyAccordionProps) => {
  const [expanded, setExpanded] = useState(expandedDefault ?? false);

  const answerClass = classNames({
    hidden: !expanded,
    visible: expanded,
  });
  const questionClass = classNames({
    expanded: expanded,
    collapsed: !expanded,
    // "active-category": isActive,
  });

  const filteredRows = rows.filter(
    (row) => row.value !== null && row.value !== "",
  );
  // const colWidthClass = showConfidenceCol ? "third-width" : "half-width";

  return (
    <ItemDiv role="button">
      {/* Clickable title bar */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
      <div
        css={accordionItemHead}
        className={questionClass}
        onClick={() => setExpanded(!expanded)}
        role="button"
      >
        <span>{title}</span>
        {titleValue && (
          <span
            className={`titleVal ${showMobileTitleValue && "mobileVisible"}`}
          >
            {titleValue}
          </span>
        )}
        {photoState && photoState.count > 0 && (
          <div
            style={{
              marginLeft: !titleValue ? "auto" : 0,
            }}
          >
            <PhotoPill
              photoCount={photoState.count}
              onClick={photoState.onClick}
            />
          </div>
        )}
        {rows.length > 0 && <Chevron className="indicator" />}
      </div>

      <div css={accordionBody} className={answerClass}>
        {/* Data rows */}
        {filteredRows.map((row) => {
          if (Array.isArray(row.value)) {
            return row.value.map((item, index) => {
              const keys = Object.keys(item);
              // expecting a key/val pair
              if (keys.length === 2) {
                return (
                  <div css={accordionRow} key={item[keys[0]] + index}>
                    <div className="cell">{item[keys[0]]}</div>
                    <div className="cell">
                      {keys[1] === "year"
                        ? `${yearsFromYear(item[keys[1]])} Years`
                        : item[keys[1]]}
                    </div>
                  </div>
                );
              }
            });
          } else {
            return row.value !== null ? (
              <div
                css={accordionRow}
                key={row.name}
                style={{
                  justifyContent: row.name === null ? "left" : "center",
                }}
              >
                {row.name !== null && <div className="cell">{row.name}</div>}
                <div className={` cell `}>
                  {row.formatFunc ? row.formatFunc(row.value) : row.value}
                </div>
              </div>
            ) : (
              ""
            );
          }
        })}
      </div>
    </ItemDiv>
  );
};
