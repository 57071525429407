import { useEffect, useRef, useState } from "react";
import {
  FullscreenControl,
  Map,
  Marker,
  NavigationControl,
  ScaleControl,
} from "mapbox-gl";
import { css } from "@emotion/react";

const mapStyles = css`
  background-color: var(--gray-medium);
  border-radius: 20px;
  height: 100%;
  width: 100%;
`;

const mapControls = css`
  background-color: white;
  border-radius: 10px;
  font-size: 12px;
  padding: 12px;
  position: absolute;
  z-index: 5;

  display: flex;
  bottom: 20px;
  left: 20px;

  button {
    appearance: none;
    border: 0;
    background: none;
    font-family: inherit;
    font-size: 12px;
  }

  button:first-child {
    cursor: pointer;
    padding-right: 20px;
  }

  button:last-child {
    cursor: pointer;
    padding-left: 20px;
    position: relative;

    &:before {
      background-color: var(--gray-light);
      content: " ";
      display: inline-block;
      height: 160%;
      left: 0;
      position: absolute;
      top: -5px;
      width: 1px;
    }
  }

  .active {
    color: #4a25e1;
    font-weight: 600;
  }
`;

type PropertyMapProps = {
  apiKey: string;
  container?: string;
  coords: [number, number];
  mapStyle?: "satellite" | "streets";
  showFullScreen?: boolean;
  showMapToggle?: boolean;
  showScale?: boolean;
};

export const PropertyMap = ({
  coords,
  container,
  apiKey,
  mapStyle = "streets",
  showFullScreen = true,
  showMapToggle = true,
  showScale = false,
}: PropertyMapProps) => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState<Map | null>(null);
  const [activeMapStyle, setActiveMapStyle] = useState("streets");
  const styles = {
    streets: "streets-v12",
    satellite: "satellite-streets-v12",
  };

  const setMapStyle = (styleId: "streets" | "satellite") => () => {
    if (!map) {
      return;
    }
    map.setStyle(`mapbox://styles/mapbox/${styles[styleId]}`);
    setActiveMapStyle(styleId);
  };

  useEffect(() => {
    if (!map && mapContainer.current) {
      const newMap = new Map({
        accessToken: apiKey,
        container: container || mapContainer.current,
        style: `mapbox://styles/mapbox/${styles[mapStyle]}`,
        center: coords,
        zoom: 16.25,
      });
      if (showFullScreen) {
        newMap.addControl(new FullscreenControl());
      }
      if (showScale) {
        const scale = new ScaleControl({
          maxWidth: 80,
          unit: "imperial",
        });
        newMap.addControl(scale);
      }
      newMap.addControl(
        new NavigationControl({
          showCompass: false,
        }),
        "bottom-right",
      );
      new Marker().setLngLat(coords).addTo(newMap);
      setMap(newMap);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div css={mapStyles} ref={mapContainer} className={"property-map"}></div>
      {showMapToggle && (
        <div css={mapControls} className={"map-controls"}>
          <button
            className={activeMapStyle === "streets" ? "active" : ""}
            onClick={setMapStyle("streets")}
          >
            Map
          </button>
          <button
            className={activeMapStyle === "satellite" ? "active" : ""}
            onClick={setMapStyle("satellite")}
          >
            Satellite
          </button>
        </div>
      )}
    </>
  );
};
