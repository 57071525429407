import { useState } from "react";

import { idRequestParamsSchema } from "~/zodLib";
import { createKaiizenSsrPage } from "~/kaiizenSsr";

import {
  getKaiListingRequestByPropertyId,
  getListingFromPrimaryPropertyId,
  getMsaFromCbsaCode,
  getPhotoSizes,
  getPropertyPhotos,
  getPropertySnapshotFromPropertyIdOrNotFoundError,
  PropertyDetailPhoto,
  PropertySnapshot,
} from "~/dataServices";

import {
  SectionContainer,
  SectionNavContainer,
  SectionNavLeft,
  SectionNavRight,
  SiteContainer,
} from "~/clients/zen/styles";
import {
  SectionNavUserRow,
  OverviewDetail,
  FinancialDetail,
  GeographicDetail,
  PropertyDetail,
  SystemsDetail,
  InteriorDetail,
  ConstructionDetail,
  MiscellaneousDetail,
  PropertyTopSummary,
  PropertySidebar,
  PropertyMap,
  SiteNav,
  NavTabs,
} from "~/clients/zen/partials";
import {
  constructionDetails,
  detailRenoSum,
  interiorDetails,
  miscDetails,
  propertyDetails,
  systemsDetails,
} from "~/clients/zen/lib";
import {
  detailStyles,
  zenDetailStyles,
} from "~/clients/zen/pages/ZenListingDetail";
import { putCoverPhotoFirst } from "~/clients/sharedUiLib/putCoverPhotoFirst";

type LoaderResult = {
  mapboxKey: string;
  property: PropertySnapshot;
  photos: PropertyDetailPhoto[];
  renoTotals: { [index: string]: number[] };
  msaName: string;
  kaiScanDate: string | null;
  listDate: string | null;
};

const getDaysAgo = (date: Date | null | undefined) =>
  date
    ? Math.ceil(
        (new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24),
      ).toString()
    : null;

export const labeledZenPropertyDetailView = {
  LabeledZenPropertyDetailView: ({
    property,
    photos,
    mapboxKey,
    msaName,
    renoTotals,
    kaiScanDate,
    listDate,
  }: LoaderResult) => {
    const [activeSection, setActiveSection] = useState<string>("overview");
    const [detailTab, setDetailTab] = useState<string>("Details");

    return (
      <SiteContainer css={zenDetailStyles}>
        <SiteNav site="zen" />
        <SectionContainer>
          <SectionNavContainer>
            <SectionNavLeft>
              <h1>{property.oneLineAddress}</h1>
              <NavTabs
                tabs={["Details", "Map"]}
                activeTab={detailTab}
                onClick={setDetailTab}
              />
            </SectionNavLeft>
            <SectionNavRight>
              <SectionNavUserRow
                onExportClick={() => `/properties/csv/${property.propertyId}`}
              />
            </SectionNavRight>
          </SectionNavContainer>
          <main className="no-padding">
            <PropertyTopSummary property={property} />
            <div css={detailStyles}>
              {detailTab === "Map" ? (
                <PropertyMap
                  apiKey={mapboxKey}
                  coords={property.state.coordinates || [0, 0]}
                />
              ) : (
                <>
                  <PropertySidebar
                    property={property}
                    selectedSection={activeSection}
                    setTab={setActiveSection}
                  />
                  {activeSection === "overview" && (
                    <OverviewDetail
                      property={property}
                      photos={photos}
                      mapboxKey={mapboxKey}
                      kaiScanDate={kaiScanDate}
                      listDate={listDate}
                    />
                  )}
                  {activeSection === "financial" && (
                    <FinancialDetail
                      property={property}
                      photos={photos}
                      mapboxKey={mapboxKey}
                      renoTotals={renoTotals}
                    />
                  )}
                  {activeSection === "geographic" && (
                    <GeographicDetail
                      property={property}
                      mapboxKey={mapboxKey}
                      msaName={msaName || ""}
                    />
                  )}
                  {activeSection === "systems" && (
                    <SystemsDetail
                      property={property}
                      photos={photos}
                      renoTotal={renoTotals.systems}
                    />
                  )}
                  {activeSection === "interior" && (
                    <InteriorDetail
                      property={property}
                      photos={photos}
                      renoTotal={renoTotals.interior}
                    />
                  )}
                  {activeSection === "construction" && (
                    <ConstructionDetail
                      property={property}
                      photos={photos}
                      renoTotal={renoTotals.construction}
                    />
                  )}
                  {activeSection === "property" && (
                    <PropertyDetail
                      property={property}
                      photos={photos}
                      renoTotal={renoTotals.property}
                    />
                  )}
                  {activeSection === "miscellaneous" && (
                    <MiscellaneousDetail
                      property={property}
                      photos={photos}
                      renoTotal={renoTotals.miscellaneous}
                    />
                  )}
                </>
              )}
            </div>
          </main>
        </SectionContainer>
      </SiteContainer>
    );
  },
};

export const zenPropertiesDetailPage = () =>
  createKaiizenSsrPage({
    route: "/property/:id",
    input: idRequestParamsSchema,
    loader: async ({ deps, input }): Promise<LoaderResult> => {
      const propertyId = input.params.id;
      const property = await getPropertySnapshotFromPropertyIdOrNotFoundError(
        deps,
        propertyId,
      );
      const { regionMsaCode } = property;

      const renoTotals: Record<string, number[]> = {
        property: detailRenoSum(propertyDetails, property),
        miscellaneous: detailRenoSum(miscDetails, property),
        construction: detailRenoSum(constructionDetails, property),
        interior: detailRenoSum(interiorDetails, property),
        systems: detailRenoSum(systemsDetails, property),
      };

      renoTotals.total = Object.values(renoTotals).reduce(
        ([a1, a2], [b1, b2]) => [a1 + b1, a2 + b2],
        [0, 0],
      );

      return {
        property,
        renoTotals,
        mapboxKey: deps.config.mapbox.apiKey,
        photos: await getPropertyPhotos(deps, { propertyId }).then((photos) => {
          const _photos = photos.map((photo) => ({
            id: photo.id,
            tags: photo.tags ?? [],
            kaiDescription: photo.kaiDescription ?? "",
            urls: getPhotoSizes(deps, photo),
          }));

          return putCoverPhotoFirst(_photos, property.coverPhotoId);
        }),
        msaName:
          (regionMsaCode &&
            (await getMsaFromCbsaCode(deps, regionMsaCode))?.name) ??
          "",
        kaiScanDate:
          (
            await getKaiListingRequestByPropertyId(deps, propertyId)
          )?.createdAt.toString() || null,
        listDate: getDaysAgo(
          (await getListingFromPrimaryPropertyId(deps, propertyId))?.createdAt,
        ),
      };
    },
    labeledView: labeledZenPropertyDetailView,
  });
