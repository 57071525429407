import styled from "@emotion/styled";
import { breakpointM, breakpointXL, colors } from "../../sharedStyles";

export const PhotoCarouselPage = styled.div`
  width: 100%;

  @media (max-width: ${breakpointM}) {
    max-height: calc(100vh - 80px);
  }
`;

export const ContainerColumn = styled.div`
  width: 50%;

  &.left {
    margin-right: 30px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .container__head {
      padding-top: 0;

      @media (min-width: ${breakpointXL}) {
        padding-top: 10px;
      }
    }
  }
`;

export const PhotoCarouselOuterNav = styled.div`
  display: flex;
  color: white;
  width: 100%;

  .button {
    &--close {
      align-self: center;
      background: none;
      border: none;
      height: 20px;
      width: 20px;
    }
  }

  .item {
    appearance: none;
    background: none;
    border: none;
    // apparently 'calt' stands for 'uuucontextual alternates'. no idea.
    font-feature-settings: "calt" off;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    line-height: 44px;
    margin-right: 60px;
    position: relative;
    text-decoration: none;
    text-indent: 10px;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    &.active {
      position: relative;

      &:after {
        background: white;
        border-radius: 16px;
        content: " ";
        display: block;
        height: 4px;
        position: relative;
        top: -7px;
        width: calc(100% + 10px);
      }
    }
  }

  .nav {
    display: flex;
    margin-left: 75px;
  }

  .title {
    font-family: "Bricolage Grotesque", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    text-decoration: none;

    // the chevron svg serves as a 'back' link on the detail page
    svg {
      position: relative;
      right: 3px;
      top: 6px;
    }
  }
`;

// white background, black text
export const PhotoCarouselInnerNav = styled.div`
  background-color: #4554a7;
  display: flex;
  height: 80px;
  align-content: center;
  padding: 20px 0 20px 30px;
  width: calc(100% - 100px);

  &.reverse {
    align-content: center;
    background-color: white;
    color: ${colors.purple};
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px;
    width: 100%;

    > .item--selected:after {
      background: purple;
    }

    .item {
      color: #7b779f;

      &.active {
        color: black;

        &:after {
          background: black;
        }
      }
    }

    .title {
      color: black;
      font-size: 18px;
      line-height: 1.2;
      width: 80%;

      @media (max-width: ${breakpointM}) {
        a {
          display: none;
        }
      }
    }
  }
`;
