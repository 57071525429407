import { StrictMode } from "react";
import { ServerStatePayload } from "./ServerStatePayload";
import { UserContextProvider } from "./UserContext";
import Cookies from "universal-cookie";
import { CookiesProvider } from "react-cookie";
import { MaterialUiProvider } from "./MaterialUiProvider";
import { AllModals, ModalContextProvider } from "~/clients/sharedUiLib/modal";

type AppProps = {
  View: React.FC;
  serverState: ServerStatePayload;
  cookies?: Cookies; // will be undefined client-side, hydrating from browser
};

const AppViewWithViewState = ({
  View,
  viewState,
}: {
  View: React.FC;
  viewState: any;
}) => {
  return <View {...viewState} />;
};

export function App({
  View,
  serverState: { user, viewState, releaseKey },
  cookies,
}: AppProps) {
  return (
    <MaterialUiProvider>
      <StrictMode>
        <CookiesProvider cookies={cookies}>
          <UserContextProvider value={{ user }}>
            <ModalContextProvider>
              <div
                id="app-metadata"
                style={{ display: "none" }}
                data-user={user}
                data-version={releaseKey}
              ></div>
              <AppViewWithViewState {...{ View, viewState }} />
              <AllModals />
            </ModalContextProvider>
          </UserContextProvider>
        </CookiesProvider>
      </StrictMode>
    </MaterialUiProvider>
  );
}
