import { css } from "@emotion/react";
import { navObject } from "~/clients/sharedUiLib";

export const styles = css`
  line-height: 16px;

  .crumb {
    color: var(--gray-medium);
    font-weight: 400;
    font-size: 12px;
    transition: color 200ms;
    text-decoration: none;
  }

  a.crumb:hover {
    color: white;
  }

  .crumb + .crumb {
    &:before {
      content: " / ";
    }
  }

  .crumb:last-child {
    font-weight: 600;
  }
`;

export const BreadCrumb = ({ links }: { links: navObject[] }) => {
  return (
    <div css={styles}>
      {links.map((link, i) =>
        link.path ? (
          <a className="crumb" href={link.path} key={i}>
            {link.title}
          </a>
        ) : (
          <span className="crumb" key={i}>
            {link.title}
          </span>
        ),
      )}
    </div>
  );
};
