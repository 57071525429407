import { useState } from "react";
import styled from "@emotion/styled";
import { CsvSvg, ConfettiSvg } from "../../sharedAssets";
import { Button } from "~/clients/zen/widgets";

const DownloadDiv = styled.div`
  text-align: center;

  p {
    color: var(--gray-medium);
    font-size: 18px;
  }

  &__img {
    align-content: center;
    border-radius: 13px;
    height: 171px;
    margin-bottom: 30px;
    width: 100%;

    &--csv {
      background-color: #f4f7fe;
    }

    &--success {
      background-color: #1cc6de;

      img:nth-child(2) {
        margin: 0 20px;
      }

      img:last-child {
        transform: rotate(180deg);
      }
    }
  }
`;

export const DownloadModal = ({
  closeFunction,
  count,
  address,
}: {
  closeFunction: () => void;
  count?: number;
  address?: string;
}) => {
  const [finished, setFinished] = useState(false);

  const closeAndReset = () => {
    closeFunction();
    setFinished(false);
  };

  const hasCount = typeof count === "number";
  let propertyText = "";

  if (address) {
    propertyText = `<strong>${address}</strong> awaits`;
  } else if (hasCount && count > 0) {
    propertyText =
      count === 1
        ? "<strong>1 property</strong> awaits"
        : `<strong>${count} properties</strong> await`;
  }

  propertyText +=
    " your analysis & input, get started by downloading the Kaiizen excel.";

  return (
    <DownloadDiv>
      {!finished && (
        <>
          <h2>Download Kaiizen Data</h2>
          <p dangerouslySetInnerHTML={{ __html: propertyText }}></p>
          <div className="download-dialog__img download-dialog__img--csv">
            <CsvSvg />
          </div>
          <Button
            color="purple"
            onClick={() => {
              setFinished(true);
            }}
          >
            Download Data
          </Button>
        </>
      )}
      {finished && (
        <>
          <h2>Download Successful!</h2>
          <p>
            After your analysis, reach out to via email to let us know your
            decisions.
          </p>
          <div className="download-dialog__img download-dialog__img--success">
            <ConfettiSvg />
            <CsvSvg />
            <ConfettiSvg />
          </div>
          <Button color="purple" onClick={closeAndReset}>
            Ok, Great!
          </Button>
        </>
      )}
    </DownloadDiv>
  );
};
