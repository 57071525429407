import { PropertySnapshot } from "~/dataServices";

import {
  BoltIcon,
  BoxIcon,
  ClipboardIcon,
  DollarIcon,
  DoorIcon,
  HouseIcon,
  MapPinIcon,
  TreeIcon,
} from "~/clients/zen/assets";
import { useModal } from "~/clients/zen/hooks";
import { Modal, DownloadModal, LinkButton } from "~/clients/zen/widgets";

import { categoryItemStyles } from "./CategoryItem";
import { css } from "@emotion/react";
import { breakpointM, colors } from "~/clients/sharedStyles";

const categoryTabs = [
  "overview",
  "financial",
  "geographic",
  "systems",
  "interior",
  "construction",
  "property",
  "miscellaneous",
] as const;

type PropertyDetailsTab = (typeof categoryTabs)[number];

type PropertySidebarProps = {
  property: PropertySnapshot;
  selectedSection: string;
  setTab: (tab: PropertyDetailsTab) => void;
};

const iconMap: { [index: string]: any } = {
  construction: HouseIcon,
  financial: DollarIcon,
  geographic: MapPinIcon,
  interior: DoorIcon,
  miscellaneous: BoxIcon,
  overview: ClipboardIcon,
  property: TreeIcon,
  systems: BoltIcon,
};

const SideBarIcon = ({ tabName }: { tabName: string }) => {
  const SBI = iconMap[tabName];
  return <SBI />;
};

const styles = css`
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  width: 20%;

  @media (max-width: ${breakpointM}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 30px;
    width: 100%;
  }

  h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding: 25px 0;

    &:after {
      background-color: ${colors["gray-divider"]};
      content: " ";
      display: block;
      height: 1px;
      position: relative;
      top: 20px;
      width: 100%;
    }
  }
  @media (max-width: ${breakpointM}) {
    gap: 20px;
    min-height: 46px;
    overflow-x: scroll;
    padding: 10px 20px;

    &::-webkit-scrollbar {
      display: none;
    }

    button {
      font-size: 14px;
      &:last-child {
        max-width: 32px;
        overflow: hidden;
      }
    }
    h4,
    svg {
      display: none;
    }
  }
`;

export const PropertySidebar = ({
  property,
  selectedSection,
  setTab,
}: PropertySidebarProps) => {
  const downloadModalState = useModal();

  return (
    <div className="container" css={styles}>
      <h4>Category</h4>
      {categoryTabs.map((category) => (
        <LinkButton
          key={category}
          css={categoryItemStyles}
          className={`${category} ${
            category === selectedSection ? "active" : ""
          }`}
          onClick={() => setTab(category)}
        >
          <SideBarIcon tabName={category} /> {category}
        </LinkButton>
      ))}

      <Modal modalHook={downloadModalState}>
        {downloadModalState.isVisible && (
          <DownloadModal
            closeFunction={downloadModalState.closeModal}
            address={`${property.houseNumber} ${property.street}`}
          />
        )}
      </Modal>
    </div>
  );
};
