import { useState } from "react";

import { PropertySnapshot, PropertyDetailPhoto } from "~/dataServices";

import { formatRoomCount } from "~/lib/presenters";

import { detailIsIncluded } from "~/clients/zen/lib";
import {
  accordionPhotoState,
  DetailScreen,
  filterDetailPhotos,
  PhotoNavigator,
  formatRenoArray,
  PropertyAccordion,
} from "~/clients/zen/partials";

export const InteriorDetail = ({
  property,
  photos,
  renoTotal,
}: {
  property: PropertySnapshot;
  photos: PropertyDetailPhoto[];
  renoTotal: number[];
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  const { state: propVals } = property;
  const filteredPhotos = filterDetailPhotos(photos, "interior");

  const getInteriorPhotoState = accordionPhotoState(
    filteredPhotos,
    setPhotoIndex,
  );

  return (
    <DetailScreen className="container">
      <h4>Interior</h4>
      <div className="wrap">
        <div className="left">
          {detailIsIncluded("bedrooms", propVals) && (
            <PropertyAccordion
              title="Bedrooms"
              titleValue={formatRoomCount(property.bedCount)}
              photoState={getInteriorPhotoState("interior/bedrooms")}
              showMobileTitleValue={true}
              rows={[
                {
                  name: "Bedrooms",
                  value: formatRoomCount(property.bedCount) ?? "",
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.bedroomsRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("bathrooms", propVals) && (
            <PropertyAccordion
              title="Bathrooms"
              titleValue={formatRoomCount(property.state.bathroomsFullCount)}
              photoState={getInteriorPhotoState("interior/bathrooms")}
              showMobileTitleValue={true}
              rows={[
                {
                  name: "Full Bathrooms",
                  value:
                    formatRoomCount(property.state.bathroomsFullCount) ?? "",
                },
                {
                  name: "Half Bathrooms",
                  value:
                    formatRoomCount(property.state.bathroomsHalfCount) ?? "",
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.bathroomsRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("kitchen", propVals) && (
            <PropertyAccordion
              title="Kitchen"
              photoState={getInteriorPhotoState("interior/kitchens")}
              rows={[
                { name: "appliances", value: propVals.kitchenAppliances },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.kitchenRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("laundryRoom", propVals) && (
            <PropertyAccordion
              title="Laundry Room"
              photoState={getInteriorPhotoState("interior/laundry-room")}
              rows={[
                {
                  name: "appliances",
                  value: propVals.laundryRoomAppliances,
                },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.laundryRoomRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("familyRoom", propVals) && (
            <PropertyAccordion
              title="Family Room"
              photoState={getInteriorPhotoState("interior/family-room")}
              rows={[
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.familyRoomRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("livingRoom", propVals) && (
            <PropertyAccordion
              title="Living Room"
              photoState={getInteriorPhotoState("interior/living-room")}
              rows={[
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.familyRoomRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("diningRoom", propVals) && (
            <PropertyAccordion
              title="Dining Room"
              photoState={getInteriorPhotoState("interior/dining-room")}
              rows={[
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.diningRoomRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("commonAreas", propVals) && (
            <PropertyAccordion
              title="Common Areas"
              photoState={getInteriorPhotoState("interior/common-areas")}
              rows={[
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.commonAreasRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          {detailIsIncluded("basement", propVals) && (
            <PropertyAccordion
              title="Basement"
              titleValue={propVals.basementType}
              photoState={getInteriorPhotoState("interior/basement")}
              rows={[
                { name: "Basement Type", value: propVals.basementType },
                {
                  name: "Estimated Reno Cost",
                  value: formatRenoArray(
                    propVals.basementRenoEstimateRangeDollars,
                  ),
                },
              ]}
            />
          )}
          <PropertyAccordion
            title="Total Interior Reno"
            titleValue={formatRenoArray(renoTotal)}
            rows={
              [
                // { name: "Total Estimated Timeline", value: "xxx days" },
              ]
            }
          />
        </div>
        <div className={`right ${filteredPhotos.length > 0 && "photo-sizer"}`}>
          <PhotoNavigator
            photos={photos}
            currentIndex={photoIndex}
            onPhotoChangeClick={setPhotoIndex}
            photoBackgroundSize={"contain"}
            paginationAlign={"right"}
            filteredPhotos={filteredPhotos}
            category={"interior"}
            onSeeAllClick={() => {
              window.location.assign(`/property/${property.propertyId}/photos`);
            }}
          />
        </div>
      </div>
    </DetailScreen>
  );
};
