import { css } from "@emotion/react";
import { Request } from "express";

import { AllDeps } from "~/dataServices/forServer";
import { version } from "~/lib/version";
import { createKaiizenSsrPage, ErrorWithStatus } from "~/kaiizenSsr";

import {
  SectionContainer,
  SectionNavContainer,
  SiteContainer,
} from "~/clients/sharedStyles";
import { SiteNav } from "~/clients/sharedPartials";

const errorContStyles = css`
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  position: relative;
  text-align: center;
  top: 30px;
  width: 50%;
`;

export type ErrorPageLoaderResponse = {
  errorMessage: string;
  statusCode: number;
  requestId: string;
  releaseKey: string;
};

export const ErrorPageLoader = ({
  req,
  deps,
  error,
}: {
  req: Request;
  deps: AllDeps;
  error?: ErrorWithStatus;
}): ErrorPageLoaderResponse => ({
  errorMessage: error
    ? error.statusCode >= 500
      ? "Internal Error"
      : error.message
    : "Error",
  statusCode: error?.statusCode ?? 500,
  requestId: req.requestId,
  releaseKey: deps.config.app.releaseKey,
});

export const ErrorPageView = ({
  requestId,
  releaseKey,
  statusCode,
  errorMessage,
}: ErrorPageLoaderResponse) => (
  <SiteContainer>
    <SiteNav site="fusion" />
    <SectionContainer>
      <SectionNavContainer />
      <div css={errorContStyles} className="container">
        <h1>
          {errorMessage} ({statusCode})
        </h1>
        <p>
          <p>Version: {version}</p>
          <p>Release: {releaseKey}</p>
          <small>Request ID: {requestId}</small>
        </p>
      </div>
    </SectionContainer>
  </SiteContainer>
);

export const labeledErrorPageView = { ErrorPageView };

export const ErrorPage = () =>
  createKaiizenSsrPage({
    route: "/",
    loader: ErrorPageLoader,
    labeledView: labeledErrorPageView,
  });
