import { colors } from "~/clients/sharedStyles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { AnchorHTMLAttributes, ButtonHTMLAttributes, ReactNode } from "react";

type MixinColor = "blue" | "gray" | "green" | "purple" | "red" | "teal";
const buttonMixin = (color: MixinColor) =>
  `&.${color} {
    background: ${colors[`gradient-${color}`]};
    &:not(:disabled):active,
    &:not(:disabled):active:hover {
      background: ${colors[`gradient-${color}-dark`]};
    }
    &:active,
    &:not(:disabled):hover {
      background: ${colors[`gradient-${color}-light`]};
    }
    &:disabled:active:hover {
      background: ${colors[`gradient-${color}`]};
    } 
  }`;

const defaultStyles = css`
  border: none;
  border-radius: 9999px;
  box-shadow:
    0 4px 5px 0 rgba(16, 4, 90, 0.1),
    0 2px 0 0 rgba(255, 255, 255, 0.25) inset;
  color: ${colors.white};
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &:disabled {
    color: ${colors.white};

    span {
      opacity: 0.5;
    }
  }

  // no shadow on mousedown & disabled
  &:active:hover,
  &:disabled {
    box-shadow: none;
    cursor: default;
  }

  ${buttonMixin("blue")}
  ${buttonMixin("gray")}
  ${buttonMixin("green")}
  ${buttonMixin("purple")}
  ${buttonMixin("red")}
  ${buttonMixin("teal")}

  &.white {
    background: ${colors.white};
    border: solid 1px ${colors["gray-dark"]};
    color: ${colors["blue-primary"]};
    &:active,
    &:hover {
      background: ${colors["gray-light"]};
    }
    &:disabled {
      color: ${colors["gray-medium"]};
      border-color: ${colors["gray-medium"]};
      background: ${colors["gray-light"]};
    }
  }

  &.icon {
    box-shadow: none;
  }
`;

const compactStyles = css`
  align-items: center;
  background: ${colors.white};
  border-radius: 10px;
  color: ${colors["gradient-blue-dark"]};
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 5px 0 rgba(16, 4, 90, 0.2);
  display: block;
  font-size: 12px;
  font-weight: 700;
  justify-content: center;
  line-height: 100%;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: fit-content;

  &:active:hover {
    color: ${colors["gradient-blue-dark"]};
    background-color: ${colors["gray-light"]};
  }

  &:hover {
    color: ${colors["gradient-blue-light"]};
  }

  &:disabled {
    color: ${colors["gray-medium"]};
  }

  &.green {
    color: ${colors["gradient-green-dark"]};

    &:hover {
      color: ${colors["gradient-green-light"]};
    }

    &:active:hover {
      color: ${colors["gradient-green-dark"]};
    }
  }

  &.red {
    color: ${colors["gradient-red-dark"]};

    &:active:hover {
      color: ${colors["gradient-red-light"]};
    }
    &:hover {
      color: ${colors["gradient-red-dark"]};
    }
  }
`;

const anchorButton = styled.button`
  ${defaultStyles}
`;
export const AnchorButton = anchorButton.withComponent("a");

type ButtonColors =
  | "blue"
  | "gray"
  | "green"
  | "purple"
  | "red"
  | "teal"
  | "white";
type ButtonSizes = "compact" | "default";
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    children: ReactNode;
    className?: string;
    color?: ButtonColors;
    disabled?: boolean;
    href?: string;
    onClick?: () => void;
    size?: ButtonSizes;
  };

export const Button = ({ children, ...props }: ButtonProps) => {
  const color = props.color ?? "blue";
  let buttonStyle = defaultStyles;
  if (props.size && props.size === "compact") {
    buttonStyle = compactStyles;
  }

  if (props.href) {
    return (
      <a href={props.href} className={color} css={buttonStyle} {...props}>
        {children}
      </a>
    );
  }

  return (
    <button css={buttonStyle} className={color} {...props}>
      <span>{children}</span>
    </button>
  );
};
