// TODO: we don't need this for viewState after there REFACTOR
//   so, we can just simplify this to just: UserContext
import { useContext, createContext } from "react";

import { AuthenticatedUser } from "~/dataServices";

export type UserContextState = { user: AuthenticatedUser | null };
const UserContext = createContext<UserContextState>({ user: null });

const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: UserContextState;
}) => <UserContext.Provider value={value}>{children}</UserContext.Provider>;

export const useUser = () => useUserContext().user || null;
