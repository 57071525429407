import { PropertySnapshot } from "~/dataServices";

export const detailSections = [
  "construction",
  "interior",
  "miscellaneous",
  "property",
  "systems",
];
export const propertyDetails = [
  "additionalStructures",
  "driveway",
  "fencing",
  "pool",
];

export const miscDetails = ["environmental", "permits", "zoning"];

export const constructionDetails = [
  "addition",
  "attic",
  "crawlspace",
  "facade",
  "fireplaceAndChimney",
  "foundation",
  "parking",
  "roof",
  "solar",
];

export const interiorDetails = [
  "basement",
  "bathrooms",
  "bedrooms",
  "commonAreas",
  "diningRoom",
  "familyRoom",
  "kitchen",
  "laundryRoom",
  "livingRoom",
];

export const systemsDetails = ["electrical", "hvac", "plumbing"];

export const detailRenoSum = (keys: string[], property: PropertySnapshot) => {
  const sum = [0, 0];
  let key;

  keys.forEach((detailKey) => {
    key = detailKey + "RenoEstimateRangeDollars";
    if (property.state[key]) {
      // @ts-expect-error seriously super useful!!
      sum[0] += property.state[key][0];
      // @ts-expect-error love this stuff!!
      sum[1] += property.state[key][1];
    }
  });

  return sum;
};

export const detailIsIncluded = (group: string, propValues: any) =>
  !(propValues.excludedItems && propValues.excludedItems.includes(group));

export const detailArrayAsString = (details?: any[] | string | null) => {
  if (!details) return "";

  if (Array.isArray(details)) {
    const detailStrings = details.map((detail) => {
      if (typeof detail === "string") {
        return detail;
      }

      return detail[Object.keys(detail)[0]];
    });
    // don't overflow the accordion head
    if (detailStrings.length <= 2) {
      return detailStrings.join(", ");
    } else {
      return detailStrings[0] + ", " + detailStrings[1];
    }
  }

  return details;
};
