import { css } from "@emotion/react";

import { Chevron, CameraIcon, NoPhotoIcon } from "~/clients/sharedAssets";
import { breakpointM, breakpointS, colors } from "~/clients/sharedStyles";

import { PhotoNavigatorTypes } from "./PhotoNavigatorTypes";

const styles = css`
  background-color: ${colors["gray-medium"]};
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  flex-grow: 1;
  padding: 20px;

  &.photo-sizer {
    @media (max-width: ${breakpointM}) {
      height: 50vh;
    }
  }

  .nav {
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 100px;
    display: flex;
    gap: 10px;
    margin-top: auto;
    padding: 4px 16px;
    width: fit-content;

    &:hover {
      background: ${colors.white};
    }

    button {
      appearance: none;
      background: none;
      border: none;
      cursor: pointer;

      &.see-all {
        align-items: center;
        border-right: 1px solid #a3aed0;
        color: black;
        display: flex;
        font-weight: 600;
        padding-right: 15px;
        text-indent: 10px;

        svg {
          fill: ${colors["blue-primary"]};
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &:disabled {
        cursor: default;

        svg {
          fill: ${colors["gray-medium"]};
        }
      }

      &:hover {
        svg {
          fill: ${colors["blue-focus"]};
        }
      }

      &:last-of-type {
        transform: scaleX(-1);
      }
    }
  }

  &.paginationAlign-center {
    .nav {
      margin: auto auto 0 auto;
    }
  }

  &.paginationAlign-right {
    .nav {
      margin-left: auto;
    }
  }
`;

const noImages = css`
  align-items: center;
  background-color: ${colors["gray-light"]};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  @media (max-width: ${breakpointS}) {
    display: none;
  }
`;

export const PhotoNavigator = ({
  currentIndex,
  onPhotoChangeClick,
  onSeeAllClick,
  // onSeeNotesClick,
  paginationAlign,
  photoBackgroundSize,
  photos,
  filteredPhotos,
  category,
}: PhotoNavigatorTypes) => {
  const hasNoPhotos = filteredPhotos?.length === 0 || photos.length === 0;
  if (hasNoPhotos) {
    return (
      <div css={noImages} className="no-images">
        <NoPhotoIcon />
        <h2>No {category ?? ""} images available</h2>
      </div>
    );
  }

  if (currentIndex < 0 || currentIndex >= photos.length) {
    throw new Error("Cannot show non-existent photo");
  }

  // const photo = photos[currentIndex];
  const photosLength = filteredPhotos
    ? filteredPhotos.length - 1
    : photos.length - 1;
  const next = currentIndex === photosLength ? null : currentIndex + 1;
  const prev = currentIndex === 0 ? null : currentIndex - 1;

  const Arrow = ({ direction }: { direction: "prev" | "next" }) => {
    const target = direction === "prev" ? prev : next;
    const onClick = () => {
      if (target !== null) {
        onPhotoChangeClick(target);
      }
    };

    return (
      <button disabled={target === null} onClick={onClick}>
        <Chevron />
      </button>
    );
  };

  const imageUrl = filteredPhotos
    ? filteredPhotos[currentIndex]?.urls.large
    : photos[currentIndex]?.urls.large;

  return (
    <div
      css={styles}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: photoBackgroundSize,
      }}
      className={`paginationAlign-${paginationAlign} ${!hasNoPhotos && "photo-sizer"}`}
    >
      {/* If a "show notes" click callback was provided, that means we can
      show the notes button */}
      {/* TODO re-enable when photos are fixed */}
      {/* {onSeeNotesClick && photo.notes.length > 0 && (
        <div className="pill notes">
          <button onClick={onSeeNotesClick} className="notes">
            <SpeechBubbleIcon />
            Notes
          </button>
        </div>
      )} */}
      <div className="nav">
        {/* If a "see all" click callback was provided, that means we can
        show the "see all" button */}
        {onSeeAllClick && (
          <button onClick={onSeeAllClick} className="see-all">
            <CameraIcon />
            See all
          </button>
        )}
        <Arrow direction="prev" />
        {filteredPhotos ? (
          <div>
            <strong>
              {currentIndex + 1} of {filteredPhotos.length}
            </strong>
            &nbsp;
            <span>of {photos.length}</span>
          </div>
        ) : (
          <div>
            <strong>{currentIndex + 1}</strong>
            &nbsp;
            <span>of {photos.length}</span>
          </div>
        )}
        <Arrow direction="next" />
      </div>
    </div>
  );
};
