import { PropertySnapshot, floodZoneMap } from "~/dataServices";
import {
  PropertyAccordion,
  DetailScreen,
  PropertyMap,
} from "~/clients/zen/partials";
import { detailIsIncluded } from "~/clients/zen/lib";

const mileageMap: Record<number, string> = {
  0.249: "< 0.25 miles",
  0.499: "0.25 - 05 miles",
  0.999: "0.5 - 1 miles",
  99: "> 5 miles",
};

const getProximityString = (mileage: number) => {
  return mileageMap[mileage] ? mileageMap[mileage] : "Unknown";
};

export const GeographicDetail = ({
  property,
  mapboxKey,
  msaName,
}: {
  property: PropertySnapshot;
  mapboxKey: string;
  msaName: string;
}) => {
  const { state: propVals } = property;

  return (
    <DetailScreen className="container">
      <h4>Geographic</h4>
      <div className="wrap">
        <div className="left">
          <PropertyAccordion
            title="Location"
            titleValue={property.stateCode}
            expandedDefault={true}
            rows={[
              {
                name: "MSA",
                value: msaName,
              },
              {
                name: "Address",
                value: `${property.houseNumber} ${property.street}`,
              },
              {
                name: "City",
                value: property.city,
              },
              {
                name: "Zip Code",
                value: property.zip,
              },
              {
                name: "State",
                value: property.stateCode,
              },
              {
                name: "County",
                value: property.state._regionDeterminations?.county?.name ?? "",
              },
            ]}
          />
          {detailIsIncluded("floodZoneType", propVals) && (
            <PropertyAccordion
              title="Flood Zone"
              titleValue={
                propVals.floodZoneType && floodZoneMap[propVals.floodZoneType]
              }
              // titleValue={geo.floodZone ? "Yes" : "No"}
              rows={[]}
            />
          )}
          {detailIsIncluded("schools", propVals) && (
            <PropertyAccordion
              title="Schools"
              titleValue={propVals.schoolDistrictName}
              rows={[
                {
                  name: "District",
                  value: propVals.schoolDistrictName,
                },
                {
                  name: "Elementary",
                  value: propVals.schoolElementarySchoolScore,
                  formatFunc: (val) => `${val}/10`,
                },
                {
                  name: "Middle",
                  value: propVals.schoolMiddleSchoolScore,
                  formatFunc: (val) => `${val}/10`,
                },
                {
                  name: "High",
                  value: propVals.schoolHighSchoolScore,
                  formatFunc: (val) => `${val}/10`,
                },
              ]}
            />
          )}
          {detailIsIncluded("proximity", propVals) && (
            <PropertyAccordion
              title="Proximity"
              rows={[
                {
                  name: "Distance to High-Power Lines",
                  value: propVals.proximityToHighPowerLinesMiles,
                  formatFunc: (val) => getProximityString(val),
                },
                {
                  name: "Distance to Arterial Roadways",
                  value: propVals.proximityToArterialRoadwaysMiles,
                  formatFunc: (val) => getProximityString(val),
                },
                {
                  name: "Distance to Railways",
                  value: propVals.proximityToRailwaysMiles,
                  formatFunc: (val) => getProximityString(val),
                },
                {
                  name: "Distance to Commercial/Industrial Areas",
                  value: propVals.proximityToCommercialOrIndustrialAreasMiles,
                  formatFunc: (val) => getProximityString(val),
                },
              ]}
            />
          )}
        </div>
        <div style={{ position: "relative" }}>
          <PropertyMap
            apiKey={mapboxKey}
            coords={propVals.coordinates || [0, 0]}
          />
        </div>
      </div>
    </DetailScreen>
  );
};
