import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { createContext, ReactNode, useContext, useState } from "react";
import { Button } from "../sharedWidgets";

/**
 * A hook that provides utility functions for displaying simple modal dialogs.
 * These mimic the browser-native `window.confirm` and `window.alert` functions,
 * but with a look and feel that matches the rest of the app, and using
 * non-blocking promises instead of blocking the main thread.
 *
 * Example:
 * ```typescript
 * import { useSimpleModals } from "~/clients/sharedUiLib";
 *
 * function MyComponent() {
 *   const { confirm, alert } = useSimpleModals();
 *   const handleDelete = async () => {
 *     const confirmed = await confirm("Are you sure?");
 *     if (confirmed) {
 *       // ...delete the thing
 *       await alert("It's been deleted.");
 *     }
 *   }
 *   return <button onClick={handleDelete}>Delete</button>;
 * }
 * ```
 */
export function useSimpleModals() {
  const { confirm, alert } = useContext(ModalContext);
  return { confirm, alert };
}

type ModalStack = [number, ReactNode][];
const ModalContext = createContext<{
  modals: ModalStack;
  confirm: (
    message: string | ReactNode,
    opts?: {
      title?: string;
      confirmText?: string;
      cancelText?: string;
    },
  ) => Promise<boolean>;
  alert: (
    message: string | ReactNode,
    opts?: {
      title?: string;
      okayText?: string;
    },
  ) => Promise<void>;
}>({
  modals: [],
  confirm: async () => false,
  alert: async () => {},
});

let nextModalId = 1;

export function ModalContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [modals, setModals] = useState<ModalStack>([]);

  const pushModal = (modal: ReactNode) => {
    const key = nextModalId++;
    setModals([...modals, [key, modal]]);
    return key;
  };

  const removeModal = (key: number) => {
    setModals(modals.filter(([k]) => k !== key));
  };

  /**
   * Displays a "confirm" dialog with the given content, with "Confirm" and
   * "Cancel" buttons. Returns a promise that:
   * * resolves to `true` if the user clicks "Confirm",
   * * resolves to `false` if the user clicks "Cancel" or closes the dialog,
   * * and never rejects.
   *
   * A second argument can be provided to customize the dialog.
   *
   * Usage:
   * ```typescript
   * function MyComponent() {
   *   const { confirm } = useSimpleModals();
   *   const handleDelete = async () => {
   *     const confirmed = await confirm("Are you sure you want to delete this?");
   *     if (confirmed) {
   *       // ...delete the thing
   *     }
   *   }
   *   return <button onClick={handleDelete}>Delete</button>;
   * }
   * ```
   */
  const confirm = async (
    message: string | ReactNode,
    {
      title,
      confirmText,
      cancelText,
    }: {
      title?: string;
      confirmText?: string;
      cancelText?: string;
    } = {},
  ) => {
    return new Promise<boolean>((resolve) => {
      function answer(answer: boolean) {
        resolve(answer);
        removeModal(key);
      }
      const key = pushModal(
        <Dialog open={true} onClose={() => answer(false)}>
          {title && <DialogTitle>{title}</DialogTitle>}
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="white" onClick={() => answer(false)}>
              {cancelText || "Cancel"}
            </Button>
            <Button onClick={() => answer(true)}>
              {confirmText || "Confirm"}
            </Button>
          </DialogActions>
        </Dialog>,
      );
    });
  };

  /**
   * Displays an "alert" dialog with the given content and an "Okay" button.
   * Returns a promise that resolves when the user clicks "Okay" or closes the
   * dialog. The promise never rejects.
   *
   * A second argument can be provided to customize the dialog.
   *
   * Usage:
   * ```typescript
   * function MyComponent() {
   *   const { alert } = useSimpleModals();
   *   async function showAlert() {
   *     await alert("This is an alert!");
   *   }
   *   return <button onClick={showAlert}>Show Alert</button>;
   * }
   * ```
   */
  const alert = async (
    message: string | ReactNode,
    {
      title,
      okayText,
    }: {
      title?: string;
      okayText?: string;
    } = {},
  ) => {
    return new Promise<void>((resolve) => {
      function ok() {
        resolve();
        removeModal(key);
      }
      const key = pushModal(
        <Dialog open={true} onClose={() => ok()}>
          {title && <DialogTitle>{title}</DialogTitle>}
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => ok()}>{okayText || "Okay"}</Button>
          </DialogActions>
        </Dialog>,
      );
    });
  };

  const value = {
    modals,
    confirm,
    alert,
  };
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

/**
 * Renders all modals that are active in the app-wide modal stack.
 *
 * This is ONLY used in the root of the app; do not use it elsewhere.
 */
export function AllModals() {
  const { modals } = useContext(ModalContext);
  return (
    <>
      {modals.map(([key, modal]) => (
        <div key={key}>{modal}</div>
      ))}
    </>
  );
}
