import { useState } from "react";

import { propPriceStr } from "~/lib/presenters";
import {
  PropertySnapshot,
  PropertyDetailPhoto,
  ListingSnapshot,
} from "~/dataServices";

import {
  PropertyMap,
  formatRenoArray,
  PropertyAccordion,
  PhotoNavigator,
  DetailScreen,
} from "~/clients/zen/partials";

export const FinancialDetail = ({
  listing,
  mapboxKey,
  photos,
  property,
  renoTotals,
}: {
  listing?: ListingSnapshot;
  mapboxKey: string;
  photos: PropertyDetailPhoto[];
  property: PropertySnapshot;
  renoTotals: { [index: string]: number[] };
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const { state: propVals } = property;

  const priceRows = () => {
    const rows = [];
    if (listing) {
      rows.push({
        name: "Asking Price",
        value: propPriceStr(listing.askingPriceDollars),
      });
      rows.push({ name: "Renovation", value: renoTotals.total });
    }

    return rows;
  };

  return (
    <DetailScreen className="container">
      <h4>Financial</h4>
      <div className="wrap">
        <div className="left">
          <PropertyAccordion
            title="Kaiizen Price"
            titleValue={"1"}
            expandedDefault={true}
            rows={priceRows()}
          />
          <PropertyAccordion
            title="Occupancy"
            rows={[
              {
                name: "Occupied",
                value: propVals.occupancyStatus,
              },
              {
                name: "Lease End Date",
                value: propVals.occupancyLeaseEndDate
                  ? new Date(propVals.occupancyLeaseEndDate).toLocaleDateString(
                      "en-US",
                    )
                  : "",
              },
            ]}
          />
          <PropertyAccordion
            title="Renovation"
            titleValue={formatRenoArray(renoTotals.total)}
            // titleValue={fin.renoStatus.value}
            rows={[
              {
                name: "Systems",
                value: formatRenoArray(renoTotals.systems),
              },
              {
                name: "Interior",
                value: formatRenoArray(renoTotals.interior),
              },
              {
                name: "Construction",
                value: formatRenoArray(renoTotals.construction),
              },
              {
                name: "Property",
                value: formatRenoArray(renoTotals.property),
              },
              {
                name: "Miscellaneous",
                value: formatRenoArray(renoTotals.miscellaneous),
              },
            ]}
          />
        </div>
        <div className="right photo-sizer">
          <PhotoNavigator
            photos={photos}
            currentIndex={photoIndex}
            onPhotoChangeClick={setPhotoIndex}
            photoBackgroundSize={"contain"}
            paginationAlign={"right"}
            onSeeAllClick={() => {
              window.location.assign(`/property/${property.id}/photos`);
            }}
          />
          <PropertyMap
            apiKey={mapboxKey}
            coords={property.state.coordinates || [0, 0]}
          />
        </div>
      </div>
    </DetailScreen>
  );
};
