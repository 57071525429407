import { colors } from "~/clients/sharedStyles";
import classNames from "classnames";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const FadeContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  min-width: 0;

  &.enable-scroll::after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    width: 50px;
    background: #ffff00;
    background: linear-gradient(
      90deg,
      rgb(from var(--blue-secondary) r g b / 0%),
      rgb(from var(--blue-secondary) r g b / 100%)
    );
    pointer-events: none;
  }
`;
const SideScroller = styled.div`
  display: flex;
  margin-bottom: -5px;
  min-width: 0;

  &.enable-scroll {
    overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
`;
const ButtonRow = styled.div`
  flex: auto 0 0;
  display: flex;
  column-gap: 20px;
  line-height: 20px;
  padding: 0 35px 5px 0;
  width: 100%;
  flex-wrap: wrap;

  &.enable-scroll {
    flex-wrap: nowrap;
    width: auto;
  }
`;

const tabStyles = css`
  appearance: none;
  background: none;
  border: none;
  padding: 10px 5px 10px 5px;
  color: ${colors["gray-medium"]};
  cursor: pointer;
  font-family: var(--fontFamily);
  font-size: 14px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;

  &:focus-visible {
    outline: none !important;
  }

  &::after {
    background-color: ${colors["gray-medium"]};
    border-radius: 64px;
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 0;
    pointer-events: none;
  }

  &.active {
    font-weight: 600;

    &.white {
      color: ${colors.white};
      &::after {
        background-color: ${colors.white};
      }
    }

    &.purple {
      color: ${colors.purple};
      &::after {
        background-color: ${colors.purple};
      }
    }
  }

  &.enabled:hover {
    &.white::after {
      background-color: ${colors.white};
    }

    &.purple::after {
      background-color: ${colors.purple};
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
`;

type Tab =
  | string
  | {
      /**
       * The text of the tab. This is also used as the key when indicating the
       * active tab, and as the argument to the onClick handler.
       */
      label: string;

      /**
       * If true, the tab will be disabled and not clickable. Defaults to false.
       */
      disabled?: boolean;

      /**
       * If provided, the tab will be rendered as an <a> tag with this href.
       */
      href?: string;
    };
type NavTabProps = {
  /**
   * The tabs to show. For simple nav bars, this can be an array of strings to
   * use as labels. If you need more control, like wanting to use <a> tags
   * instead of <button>, you can pass an array of objects including more
   * details.
   */
  tabs: Tab[];

  /**
   * The label of the tab to mark as highlighted (if any).
   */
  activeTab?: string;

  /**
   * The color scheme to use. Defaults to white, which looks good on dark
   * backgrounds. Use purple for light backgrounds.
   */
  activeColor?: "white" | "purple";

  /**
   * Called when a tab is clicked. The label of the tab is passed as an
   * argument.
   */
  onClick?: (label: string) => void;

  /**
   * If true, the tabs will scroll horizontally when they overflow the
   * container. If false, they'll wrap to the next line.
   *
   * Defaults to true.
   */
  scrollOnOverflow?: boolean;
};

export const NavTabs = (props: NavTabProps) => {
  const tabObjs = props.tabs.map((tab) =>
    typeof tab === "string" ? { label: tab } : tab,
  );
  const activeColor = props.activeColor || "white";
  const scrollClassName = classNames({
    "enable-scroll": props.scrollOnOverflow !== false,
  });
  const onClick = props.onClick;
  return (
    <FadeContainer className={scrollClassName}>
      <SideScroller className={scrollClassName}>
        <ButtonRow className={scrollClassName}>
          {tabObjs.map((tab) => {
            const className = classNames(activeColor && activeColor, {
              active: props.activeTab === tab.label,
              enabled: !tab.disabled,
              disabled: tab.disabled,
            });

            return tab.href ? (
              <a
                css={tabStyles}
                className={className}
                href={`${tab.href}`}
                key={tab.label}
                onClick={() => {
                  onClick?.(tab.label);
                }}
              >
                {tab.label}
              </a>
            ) : (
              <button
                css={tabStyles}
                className={className}
                disabled={tab.disabled}
                key={tab.label}
                onClick={() => {
                  onClick?.(tab.label);
                }}
              >
                {tab.label}
              </button>
            );
          })}
        </ButtonRow>
      </SideScroller>
    </FadeContainer>
  );
};
