export const colors = {
  "blue-focus": "#5A48F6",
  "blue-primary": "#10045A",
  "blue-secondary": "#4554A7",
  "gray-dark": "#667080",
  "gray-divider": "#E9EDF7",
  "gray-light": "#F4F7FE",
  "gray-medium": "#A3AED0",
  green: "#05CD99",
  orange: "#F4604C",
  purple: "#9185DA",
  "purple-light": "#DBD3F9",
  red: "#F4604C",
  teal: "#1CC6DE",
  white: "#FFFFFF",
  "gradient-blue": "linear-gradient(180deg, #8F9FFF 0%, #6980FB 100%)",
  "gradient-blue-dark": "#6980FB",
  "gradient-blue-light": "#8F9FFF",
  "gradient-gray": "linear-gradient(180deg, #C1CEE7 0%, #A4AFD1 100%)",
  "gradient-gray-dark": "#A4AFD1",
  "gradient-gray-light": "#C1CEE7",
  "gradient-green": "linear-gradient(180deg, #6EE277 0%, #37A935 100%)",
  "gradient-green-dark": "#37A935",
  "gradient-green-light": "#6EE277",
  "gradient-purple": "linear-gradient(180deg, #7B66F2 0%, #5C49F6 100%)",
  "gradient-purple-dark": "#5C49F6",
  "gradient-purple-light": "#7B66F2",
  "gradient-red": "linear-gradient(180deg, #F4604C 0%, #C6493E 100%)",
  "gradient-red-dark": "#C6493E",
  "gradient-red-light": "#F4604C",
  "gradient-teal": "linear-gradient(180deg, #57E2F5 0%, #23AFC2 100%)",
  "gradient-teal-dark": "#57E2F5",
  "gradient-teal-light": "#23AFC2",
};
