import { useEffect, useRef, useState } from "react";

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const modalRef = useRef<HTMLElement>();
  const modalCssClass = "modal-root--visible";

  useEffect(() => {
    const ambientClose = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        closeModal();
      }
    };

    if (!document.getElementById("modal-root")) {
      const rootEl = document.createElement("div");
      rootEl.id = "modal-root";
      rootEl.classList.add("modal-root");
      document.body.appendChild(rootEl);
    }

    const modalRoot = document.getElementById("modal-root");

    if (modalRoot) {
      modalRoot.removeEventListener("click", ambientClose);
      modalRoot.addEventListener("click", ambientClose);
      // elementRef = React.createRef();
      // el = doc.createElement('div');
    }

    return () => {
      const modalRoot = document.getElementById("modal-root");
      if (modalRoot) {
        modalRoot.classList.remove("foo");
        modalRoot.removeEventListener("click", ambientClose);
        document.body.removeChild(modalRoot);
      }
    };
  }, []);

  const closeModal = () => {
    document.documentElement.classList.remove("no-scroll");
    document.querySelector("#modal-root")!.classList.remove(modalCssClass);
    setIsVisible(false);
  };

  const showModal = () => {
    document.documentElement.classList.add("no-scroll");
    document.querySelector("#modal-root")!.classList.add(modalCssClass);
    setIsVisible(true);
  };

  return {
    isVisible,
    closeModal,
    showModal,
    modalRef,
  };
};
