import { z } from "zod";
import { Router } from "express";
import { SsrPage, SsrViewMap } from "./ssrTypes";
import {
  ComponentProps,
  SsrLoaderResponse,
  SsrPageDefinition,
  SsrLoader,
} from "./ssrTypes";
import { createInvalidRequestError, createNotFoundError } from "./errors";

const defaultLoader = (): ComponentProps => ({});

export function createSsrPage<
  T extends SsrLoaderResponse,
  Deps,
  InputSchema extends z.ZodSchema<any> | undefined,
>({
  route,
  loader,
  input,
  labeledView,
}: SsrPageDefinition<T, Deps, InputSchema>): SsrPage<T, Deps, InputSchema> {
  const [viewKey, ...rest] = Object.keys(labeledView);
  if (rest.length !== 0)
    throw new Error("labeledView must have exactly one view");

  return {
    route,
    loader: loader || (defaultLoader as SsrLoader<T, Deps, InputSchema>),
    input,
    viewKey,
    view: labeledView[viewKey],
  };
}

export const safeViewMerge = (labeledViews: SsrViewMap[]): SsrViewMap =>
  labeledViews.reduce((acc, labeledView) => {
    const keys = Object.keys(labeledView);
    // find
    const duplicateKeys = keys.filter((key) => acc[key]);
    if (duplicateKeys.length > 0)
      throw new Error(`Duplicate view keys: ${duplicateKeys.join(", ")}`);
    return { ...acc, ...labeledView };
  }, {});

// function that takes a zod-schema and "any", validates it and returns the validated type (with the correct typescript type) OR throws createInvalidRequestError
export const validateInput = <T extends z.ZodSchema<any>>(
  schema: T,
  input: any,
): z.infer<T> => {
  const result = schema.safeParse(input);
  if (!result.success) throw createInvalidRequestError();
  return result.data;
};

export const bindNotFoundHandler = (router: Router) => {
  router.all("*", () => {
    throw createNotFoundError();
  });
};
