import { css } from "@emotion/react";
import { breakpointM, breakpointXL, colors } from "~/clients/zen/styles";
import { Icon } from "~/clients/zen/widgets";

export const categoryItemStyles = css`
  color: ${colors["blue-primary"]};
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 16px;
  line-height: 26px;
  padding: 0;
  margin-top: 30px;
  text-decoration: none;
  text-transform: capitalize;

  &.won,
  &.lost,
  &.in-progress {
    padding-left: 50px;
  }

  @media (max-width: ${breakpointXL}) {
    margin-top: 25px;
  }
  
  @media (max-width: ${breakpointM}) {
    margin-top: 0;
  }

  svg {
    fill: #10045a;
    margin-right: 10px;
  }

  .count {
    margin-left: 0.2em;
  }

  &.active {
    color: ${colors["blue-focus"]};
    font-weight: 600;

    svg {
      fill: ${colors["blue-focus"]}
    }
  }

  &.selected {
    color: ${colors["blue-focus"]}
    font-weight: 600;

    svg {
      fill: ${colors["blue-focus"]}
    }
  }

  &.financial {
    svg {
      margin-right: 11px;
      position: relative;
      top: 3px;
    }
  }

  &.interior {
    svg {
      left: 3px;
      margin-right: 14px;
      position: relative;
      top: 3px;
    }
  }

  .count {
    @media (max-width: 1350px) {
      display: none;
    }
  }
`;

type CategoryItemProps = {
  category: string;
  isSelected: boolean;
  count: number;
  setSelectedCategory: (category: string) => void;
};

export const CategoryItem = ({
  category,
  isSelected,
  count,
  setSelectedCategory,
}: CategoryItemProps) => {
  const selectedClass = () => (isSelected ? "selected" : "");
  const noIconCategories = ["won", "lost", "in-progress"];

  return (
    <button
      css={categoryItemStyles}
      className={`${category} ${selectedClass()}`}
      onClick={() => setSelectedCategory(category)}
    >
      {!noIconCategories.includes(category) && <Icon name={category} />}
      {category.replace("-", " ")} <span className="count">({count})</span>
    </button>
  );
};
