import { createKaiizenSsrPage } from "~/kaiizenSsr";
import {
  SectionContainer,
  SectionNavContainer,
  SectionNavLeft,
  SectionNavRight,
  SiteContainer,
} from "~/clients/zen/styles";
import { SectionNavUserRow, SiteNav } from "~/clients/zen/partials";
import { BreadCrumb } from "../widgets";

export const labeledZenOpportunitiesListView = {
  ZenOpportunitiesListView: () => (
    <SiteContainer>
      <SiteNav site="zen" />
      <SectionContainer>
        <SectionNavContainer>
          <SectionNavLeft>
            <BreadCrumb
              links={[{ title: "Zen", path: "/" }, { title: "Opportunities" }]}
            />
            <h1>Opportunities</h1>
          </SectionNavLeft>
          <SectionNavRight>
            <SectionNavUserRow />
          </SectionNavRight>
        </SectionNavContainer>
        <main></main>
      </SectionContainer>
    </SiteContainer>
  ),
};

export const zenOpportunitiesListPage = () =>
  createKaiizenSsrPage({
    route: "/",
    loader: async ({ deps }) => ({
      opportunities: (
        await deps.dbRepositories.propertySnapshot.find({ take: 100 })
      ).map((prop) => ({
        id: prop.propertyId,
        address: `${prop.houseNumber} ${prop.street}`,
        city: "",
        state: "",
        postCode: "",
        msa: `${prop.regionMsaCode}`,
        details: {
          bedrooms: prop.bedCount,
          baths: prop.bathCount,
          sqft: prop.sqft,
          year: prop.year,
        },
      })),
    }),
    labeledView: labeledZenOpportunitiesListView,
  });
