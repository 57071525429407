import { useEffect, useRef } from "react";
import { css } from "@emotion/react";

import { useMountEffect } from "~/clients/sharedHooks";

import { colors } from "~/clients/sharedStyles";
import { ChevronIcon } from "~/clients/sharedAssets";

import { PhotoNavigator } from "./PhotoNavigator";
import { PhotoScrollerTypes } from "./PhotoNavigatorTypes";

const sectionStyles = css`
  background: none;
  padding: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const styles = css`
  margin-top: 20px;
  position: relative;

  .scroll-wrap {
    height: 80px;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .thumb-scroll {
    // match bg color of main
    background-color: ${colors["gray-light"]};
    height: 80px;
    position: absolute;
    top: 0;
    width: 50px;
    z-index: 1;

    button {
      background-color: ${colors.white};
      border: solid 1px ${colors["gray-divider"]};
      border-radius: 10px;
      cursor: pointer;
      height: 50px;
      position: relative;
      top: 15px;
      width: 37px;

      &:disabled {
        cursor: default;
        svg {
          fill: ${colors["gray-divider"]};
        }
      }

      &:hover {
        svg {
          fill: ${colors["blue-focus"]};
        }
      }

      svg {
        fill: ${colors["blue-primary"]};
      }
    }

    &:first-of-type {
      left: 0;
    }

    &:last-of-type {
      right: 0;
      transform: scaleX(-1);
    }
  }

  .thumb-container {
    display: flex;
    gap: 10px;
    padding: 0 50px;
    position: absolute;
  }

  .thumb {
    background-color: ${colors["gray-medium"]};
    background-size: cover;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    filter: brightness(0.5);
    height: 80px;
    width: 120px;

    &:hover {
      border: solid 4px ${colors["blue-primary"]};
    }

    &.active {
      border: solid 4px ${colors["blue-primary"]};
      filter: brightness(1);
    }
  }
`;

export const PhotoScroller = ({
  photos,
  currentIndex,
  setCurrentIndex,
}: PhotoScrollerTypes) => {
  const thumbsDiv = useRef<HTMLDivElement>(null);

  const getPhotoFromFragment = (fragment: string) => {
    const match = fragment.match(/photo-(\d+)/);
    if (!match) {
      return 0;
    }

    return parseInt(match[1], 10) - 1;
  };

  const showPhoto = (index: number) => {
    if (index < 0 || index >= photos.length) {
      return;
    }

    const shouldSmoothScroll = currentIndex !== undefined;

    setCurrentIndex(index);

    thumbsDiv.current?.children[index].scrollIntoView({
      inline: "center",
      behavior: shouldSmoothScroll ? "smooth" : "auto",
    });

    window.location.replace(`#photo-${index + 1}`);
  };

  const arrowScroll = (e: KeyboardEvent) => {
    if (currentIndex !== undefined) {
      if (e.code === "ArrowLeft" && currentIndex > 0) {
        showPhoto(currentIndex - 1);
      }

      if (e.code === "ArrowRight" && currentIndex < photos.length - 1) {
        showPhoto(currentIndex + 1);
      }
    }
  };

  useEffect(() => {
    if (typeof window === "object") {
      document.addEventListener("keydown", arrowScroll);

      return () => {
        document.removeEventListener("keydown", arrowScroll);
      };
    }
  });

  useMountEffect(() => {
    const initialPhoto =
      typeof window === "object"
        ? getPhotoFromFragment(window.location.hash)
        : 0;

    if (initialPhoto >= 0 && initialPhoto < photos.length) {
      showPhoto(initialPhoto);
    }
  });

  return (
    <section css={sectionStyles}>
      {currentIndex !== undefined && (
        <PhotoNavigator
          photos={photos}
          currentIndex={currentIndex}
          onPhotoChangeClick={showPhoto}
          photoBackgroundSize="contain"
          paginationAlign="right"
        />
      )}
      <div css={styles}>
        <div className="thumb-scroll">
          <button
            disabled={!currentIndex}
            onClick={() => {
              if (currentIndex) showPhoto(currentIndex - 1);
            }}
          >
            <ChevronIcon />
          </button>
        </div>
        <div className="scroll-wrap">
          <div className="thumb-container" ref={thumbsDiv}>
            {photos.map((photo, index) => {
              return (
                <button
                  key={`${photo.urls}-${index}`}
                  style={{ backgroundImage: `url(${photo.urls.thumbnail})` }}
                  className={`thumb ${currentIndex === index ? "active" : ""}`}
                  onClick={() => showPhoto(index)}
                ></button>
              );
            })}
          </div>
        </div>
        <div className="thumb-scroll">
          <button
            disabled={currentIndex === photos.length - 1}
            onClick={() => {
              if (currentIndex !== undefined) {
                if (currentIndex > -1) showPhoto(currentIndex + 1);
              }
            }}
          >
            <ChevronIcon />
          </button>
        </div>
      </div>
    </section>
  );
};
