import styled from "@emotion/styled";

export const breakpointXL = "1700px";
export const breakpointL = "1350px";
export const breakpointM = "1024px";
export const breakpointS = "740px";

export const SiteContainer = styled.div`
  display: flex;
  flex-grow: 1;
  min-width: 0;
`;

// white background, black text
export const SectionNavContainer = styled.nav`
  flex: auto 0 0;
  position: relative;
  padding: 20px 20px 10px 20px;
  min-width: 0;
  background-color: var(--blue-secondary);
  color: white;
  min-height: 130px;

  h1 {
    font-size: 24px;
    font-style: normal;
    line-height: 32px;
    margin: 12px 0 6px 0;
  }
`;

export const SectionNavLeft = styled.div`
  min-width: 0;
`;

export const SectionNavRight = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  padding: 20px 20px 20px 0;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
`;
export const SectionNavContextRow = styled.div`
  display: flex;
  gap: 20px;
  min-width: 0;
`;

export const SectionContainer = styled.div`
  //padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
`;
