import { FormEventHandler, useState } from "react";
import styled from "@emotion/styled";

import { createKaiizenSsrPage, useUser } from "~/kaiizenSsr";
import { idRequestParamsSchema } from "~/zodLib";

import { getKaiReportOrNotFoundError } from "~/dataServices";
import { zenApiClient } from "../api";

import {
  SectionContainer,
  SectionNavContainer,
  SiteContainer,
} from "~/clients/zen/styles";
import { SiteNav } from "~/clients/zen/partials";
import { Button, FormContainer, FormField } from "~/clients/zen/widgets";

// TO REFACTOR
import { sqftStr } from "~/lib/presenters";

type LoaderResult = {
  id: string;
  prop: {
    address: string | undefined;
    sqft: number | undefined;
    beds: number | undefined;
    baths: string | undefined;
  };
  photos: string[];
};

const Box = styled.div`
  margin: auto;
  padding: 30px;
  max-width: 1000px;
`;

const ImgGallery = styled.div`
  overflow: hidden;
  margin: -10px 0 0 -10px;
`;

const Img = styled.img`
  float: left;
  width: 84px;
  height: 84px;
  margin: 10px 0 0 10px;
  object-fit: cover;
`;

export const labeledZenSubmitKaiListingView = {
  ZenSubmitKaiListingView: ({ id, prop, photos }: LoaderResult) => {
    const [submitting, setSubmitting] = useState(false);
    const [wasSubmitted, setWasSubmitted] = useState(false);
    const user = useUser();

    const onSubmit: FormEventHandler = async (e) => {
      e.preventDefault();
      setSubmitting(true);
      await zenApiClient.submitKaiListingRequest.mutate({
        kaiReportId: id,
      });
      setSubmitting(false);
      setWasSubmitted(true);
    };

    return (
      <SiteContainer>
        <SiteNav site="zen" />
        <SectionContainer>
          <SectionNavContainer />

          <Box className="container">
            <h1>Request to list this property</h1>
            {wasSubmitted ? (
              <p className="success">
                Thanks! Your property has been submitted. We&apos;ll review it
                shortly.
              </p>
            ) : (
              <>
                <p>
                  You&apos;re ready to submit this property to the Zen
                  Marketplace! Please confirm the details below to submit it for
                  review.
                </p>
                <FormContainer>
                  <form method="post" action="" onSubmit={onSubmit}>
                    <FormField label="Submitted by">{user?.name}</FormField>
                    <FormField label="Property Address">
                      {prop.address}
                    </FormField>
                    <FormField label="Bedrooms">{prop.beds || "--"}</FormField>
                    <FormField label="Bathrooms">
                      {prop.baths || "--"}
                    </FormField>
                    <FormField label="Square footage">
                      {sqftStr(prop.sqft)}
                    </FormField>
                    <FormField label="Photos" valign="top">
                      <ImgGallery>
                        {photos.map((photo, i) => (
                          <Img key={i} src={photo} />
                        ))}
                      </ImgGallery>
                    </FormField>

                    <Button
                      className="purple"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                  </form>
                </FormContainer>
              </>
            )}
          </Box>
        </SectionContainer>
      </SiteContainer>
    );
  },
};

export const zenSubmitKaiListingSsrPage = () =>
  createKaiizenSsrPage({
    route: "/submit-from-kai/:id",
    input: idRequestParamsSchema,
    loader: async ({ input, deps }): Promise<LoaderResult> => {
      const report = await getKaiReportOrNotFoundError(deps, input.params.id);

      const {
        oneLineAddress: address,
        sqft,
        bedCount: beds,
        bathCount: baths,
      } = await deps.propertyMatcher.findOrCreateProperty(report);

      return {
        id: input.params.id,
        prop: { address, sqft, beds, baths },
        photos: report.photos.slice(0, 21).map((photo) => photo.url),
      };
    },
    labeledView: labeledZenSubmitKaiListingView,
  });
